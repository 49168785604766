<template>
  <div>
    <div class="content_box beforetab" id="working-progress-box">
      <form id="searchForm" @submit.prevent>
        <table class="tbl_search">
          <colgroup>
            <col width="180px"><col width="*"><col width="100px">
          </colgroup>
          <tbody>
            <tr>
              <td>
                <input v-model="searchParams.workType" type="radio" id="workType_E" name="workType" value="E" @change="changeCalcEiCatCd()">
                <label for="workType_E" class="mr10"><span></span>{{ $t('msg.ONEX010T010.002') }}<!-- 수출 --></label>
                <input v-model="searchParams.workType" type="radio" id="workType_I" name="workType" value="I" @change="changeCalcEiCatCd()">
                <label for="workType_I"><span></span>{{ $t('msg.ONEX010T010.003') }}<!-- 수입 --></label>
              </td>
              <td class="text_left pl0">
                <div v-if="statsLoading" class="tab sub">
                  <div class="dot-flashing"></div>
                </div>
                <div class="tab sub">
                  <ul v-if="searchParams.workType === 'E'">
                    <li :class="{on: searchParams.workTab === '02'}"><a href="#" @click.prevent="fnTabChange('02')">{{ $t('msg.ONGO080G010.002') }}<!-- 미발행 --> (<span class="num">{{ exportStats.unissue }}</span>)</a></li>
                    <li :class="{on: searchParams.workTab === '01'}"><a href="#" @click.prevent="fnTabChange('01')">{{ $t('msg.ONGO080G010.001') }}<!-- 미정산 --> (<span class="num">{{ exportStats.unsettle }}</span>)</a></li>
                    <li :class="{on: searchParams.workTab === '03'}"><a href="#" @click.prevent="fnTabChange('03')">{{ $t('msg.ONGO080G010.003') }}<!-- 미반입 --> (<span class="num">{{ exportStats.unreturn }}</span>)</a></li>
                    <li :class="{on: searchParams.workTab === '04'}"><a href="#" @click.prevent="fnTabChange('04')">{{ $t('msg.ONGO080G010.004') }}<!-- 미선적 --> (<span class="num">{{ exportStats.unshipped }}</span>)</a></li>
                    <!-- <li :class="{on: searchParams.workTab === '05'}"><a href="#" @click.prevent="fnTabChange('05')">{{ $t('msg.ONGO080G010.005') }} (<span class="num">{{ exportStats.untakeout }}</span>)</a></li> -->
                  </ul>
                  <ul v-if="searchParams.workType === 'I'">
                    <li :class="{on: searchParams.workTab === '02'}"><a href="#" @click.prevent="fnTabChange('02')">{{ $t('msg.ONGO080G010.002') }}<!-- 미발행 --> (<span class="num">{{ importStats.unissue }}</span>)</a></li>
                    <li :class="{on: searchParams.workTab === '01'}"><a href="#" @click.prevent="fnTabChange('01')">{{ $t('msg.ONGO080G010.001') }}<!-- 미정산 --> (<span class="num">{{ importStats.unsettle }}</span>)</a></li>
                    <li :class="{on: searchParams.workTab === '03'}"><a href="#" @click.prevent="fnTabChange('03')">{{ $t('msg.ONGO080G010.006') }}<!-- O.B/L 미회수 --> (<span class="num">{{ importStats.unrecovery }}</span>)</a></li>
                    <li :class="{on: searchParams.workTab === '04'}"><a href="#" @click.prevent="fnTabChange('04')">{{ $t('msg.ONGO080G010.005') }}<!-- 미반출 --> (<span class="num">{{ importStats.untakeout }}</span>)</a></li>
                    <li :class="{on: searchParams.workTab === '05'}"><a href="#" @click.prevent="fnTabChange('05')">{{ $t('msg.ONGO080G010.007') }}<!-- 미반납 --> (<span class="num">{{ importStats.unreturn }}</span>)</a></li>
                  </ul>
                </div>
              </td>
              <td class="text_right">
                <!--<a class="button blue sh" href="#">검색</a> -->
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>

    <!--div class="content_box mt-2" >
      <table class="tbl_search">
        <colgroup>
          <col width="180px">
          <col width="300px">
          <col width="*">
          <col width="100px">
          <col width="100px">
        </colgroup>
        <tbody>
          <tr>
            <td>
              임시 - 추후 삭제
            </td>
            <td class="text_left pl0">
              <e-date-range-picker-simple
                id="fromDtToDt"
                :sdate="searchParams.fromDt"
                :edate="searchParams.toDt"
                @change="changeDateRange"
              />
            </td>
            <td class="text_left pl0">
              <select v-model="searchParams.searchType" style="width:100px;">
                <option value="TP01">TP01</option>
                <option value="TP02">TP02</option>
              </select>
            </td>
            <td class="text_right">
              <a class="button blue sh" href="#" @click.prevent="searchAct()">검색1</a>
            </td>
            <td class="text_right">
              <a class="button blue sh" href="#" @click.prevent="getStats()">검색2</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div-->

    <working-unsolved-export-settle ref="wpExportSettle"
                                    v-if="searchParams.workType === 'E' && searchParams.workTab === '01'"
                                    :parent-info="wpExportInitParams"
    />

    <working-unsolved-export-issue ref="wpExportIssue"
                                   v-if="searchParams.workType === 'E' && searchParams.workTab === '02'"
                                   :parent-info="wpExportInitParams"
    />

    <working-unsolved-export-return ref="wpExportReturn"
                                    v-if="searchParams.workType === 'E' && searchParams.workTab === '03'"
                                    :parent-info="wpExportInitParams"
    />
    <working-unsolved-export-shipped ref="wpExportShipped"
                                     v-if="searchParams.workType === 'E' && searchParams.workTab === '04'"
                                     :parent-info="wpExportInitParams"
    />
    <working-unsolved-export-takeout ref="wpExportTakeout"
                                     v-if="searchParams.workType === 'E' && searchParams.workTab === '05'"
                                     :parent-info="wpExportInitParams"
    />

    <working-unsolved-import-settle ref="wpImportSettle"
                                    v-if="searchParams.workType === 'I' && searchParams.workTab === '01'"
                                    :parent-info="wpImportInitParams"
    />

    <working-unsolved-import-issue ref="wpImportIssue"
                                   v-if="searchParams.workType === 'I' && searchParams.workTab === '02'"
                                   :parent-info="wpImportInitParams"
    />

    <working-unsolved-import-recovery ref="wpImportRecovery"
                                      v-if="searchParams.workType === 'I' && searchParams.workTab === '03'"
                                      :parent-info="wpImportInitParams"
    />
    <working-unsolved-import-takeout ref="wpImportTakeout"
                                     v-if="searchParams.workType === 'I' && searchParams.workTab === '04'"
                                     :parent-info="wpImportInitParams"
    />
    <working-unsolved-import-return ref="wpImportReturn"
                                    v-if="searchParams.workType === 'I' && searchParams.workTab === '05'"
                                    :parent-info="wpImportInitParams"
    />
  </div>
</template>

<script>

import { rootComputed } from '@/store/helpers'
import exportSvc from '@/api/rest/trans/exportSvc'
import importSvc from '@/api/rest/trans/importSvc'

export default {
  name: 'WorkingUnsolved',
  components: {
    WorkingUnsolvedExportSettle: () => import('@/pages/trans/WorkingUnsolvedExportSettle'),
    WorkingUnsolvedExportIssue: () => import('@/pages/trans/WorkingUnsolvedExportIssue'),
    WorkingUnsolvedExportReturn: () => import('@/pages/trans/WorkingUnsolvedExportReturn'),
    WorkingUnsolvedExportShipped: () => import('@/pages/trans/WorkingUnsolvedExportShipped'),
    WorkingUnsolvedExportTakeout: () => import('@/pages/trans/WorkingUnsolvedExportTakeout'),
    WorkingUnsolvedImportSettle: () => import('@/pages/trans/WorkingUnsolvedImportSettle'),
    WorkingUnsolvedImportIssue: () => import('@/pages/trans/WorkingUnsolvedImportIssue'),
    WorkingUnsolvedImportRecovery: () => import('@/pages/trans/WorkingUnsolvedImportRecovery'),
    WorkingUnsolvedImportTakeout: () => import('@/pages/trans/WorkingUnsolvedImportTakeout'),
    WorkingUnsolvedImportReturn: () => import('@/pages/trans/WorkingUnsolvedImportReturn'),
    EDateRangePickerSimple: () => import('@/components/common/EDateRangePickerSimple')
  },
  data () {
    return {
      wpExportInitParams: {},
      wpImportInitParams: {},
      searchParams: {
        workType: 'E', // 업무구분 (수출: E, 수입: I)
        workTab: '02', // 업무 TAB 구분 (미정산: 01, 미발행: 02, 미반입: 03, 미선적: 04, 미반출: 05)
        searchType: 'TP02',
        fromDt: '20210301',
        toDt: '20210315'
      },
      statsLoading: false,
      exportStats: {
        unsettle: 0,
        unissue: 0,
        unreturn: 0,
        unshipped: 0,
        untakeout: 0,
        unrecovery: 0
      },
      importStats: {
        unsettle: 0,
        unissue: 0,
        unreturn: 0,
        unshipped: 0,
        untakeout: 0,
        unrecovery: 0
      }
    }
  },
  computed: {
    ...rootComputed,
    selectedProfile () {
      return this.$store.getters['auth/selectedProfile']
    },
    calcEiCatCd () {
      if (this.$store.getters['auth/getCalcEiCatCd'] === 'O') {
        return 'E'
      } else {
        return this.$store.getters['auth/getCalcEiCatCd']
      }
    }
  },
  watch: {
    'searchParams.workType' () {
      this.searchParams.workTab = '02'
      this.getStats()
      this.searchAct()
    },
    calcEiCatCd () {
      this.searchParams.workType = this.calcEiCatCd
    }
  },
  created () {
    this._ = require('lodash')
    this.searchAct()
  },
  mounted () {
    const tempPrevData = sessionStorage.getItem('working-unsolved-prev-data')
    const prevData = tempPrevData === null || tempPrevData === undefined ? undefined : JSON.parse(tempPrevData)

    if (prevData !== undefined) {
      this.searchParams = this._.cloneDeep(prevData.searchParams)
    }
    this.getStats()
    this.searchAct()

    // 프로필에서 설정한 수출/수입 값으로 세팅
    this.initEiCatCd()
  },
  methods: {
    initEiCatCd () {
      if (this.calcEiCatCd !== '') {
        this.searchParams.workType = this.calcEiCatCd
      } else {
        if (this.selectedProfile.eiCatCd === 'A' || this.selectedProfile.eiCatCd === 'O') {
          // 프로필 값이 수출/수입이면 '수출'
          this.searchParams.workType = 'E'
        } else {
          // 프로필에서 설정한 수출/수입 값으로 세팅
          this.searchParams.workType = this.selectedProfile.eiCatCd
        }
      }
    },
    getStats () {
      this.statsLoading = true
      if (this.searchParams.workType === 'E') {
        this.exportStats = {
          unsettle: 0,
          unissue: 0,
          unreturn: 0,
          unshipped: 0,
          untakeout: 0,
          unrecovery: 0
        }
        exportSvc.getUnsolvedStats(this.searchParams).then(res => {
          this.exportStats = res.data
        }).finally(() => {
          this.statsLoading = false
        })
      } else if (this.searchParams.workType === 'I') {
        this.importStats = {
          unsettle: 0,
          unissue: 0,
          unreturn: 0,
          unshipped: 0,
          untakeout: 0,
          unrecovery: 0
        }
        importSvc.getUnsolvedStats(this.searchParams).then(res => {
          this.importStats = res.data
        }).finally(() => {
          this.statsLoading = false
        })
      }
    },
    searchAct () {
      if (this.searchParams.workType === 'E') {
        if (this.searchParams.workTab === '01') {
          if (this.$refs.wpExportSettle === undefined) {
            this.wpExportInitParams = this._.cloneDeep(this.searchParams)
          } else {
            this.$refs.wpExportSettle.search(this.searchParams)
          }
        } else if (this.searchParams.workTab === '02') {
          if (this.$refs.wpExportIssue === undefined) {
            this.wpExportInitParams = this._.cloneDeep(this.searchParams)
          } else {
            this.$refs.wpExportIssue.search(this.searchParams)
          }
        } else if (this.searchParams.workTab === '03') {
          if (this.$refs.wpExportReturn === undefined) {
            this.wpExportInitParams = this._.cloneDeep(this.searchParams)
          } else {
            this.$refs.wpExportReturn.search(this.searchParams)
          }
        } else if (this.searchParams.workTab === '04') {
          if (this.$refs.wpExportShipped === undefined) {
            this.wpExportInitParams = this._.cloneDeep(this.searchParams)
          } else {
            this.$refs.wpExportShipped.search(this.searchParams)
          }
        } else if (this.searchParams.workTab === '05') {
          if (this.$refs.wpExportTakeout === undefined) {
            this.wpExportInitParams = this._.cloneDeep(this.searchParams)
          } else {
            this.$refs.wpExportTakeout.search(this.searchParams)
          }
        }
      } else if (this.searchParams.workType === 'I') {
        if (this.searchParams.workTab === '01') {
          if (this.$refs.wpImportSettle === undefined) {
            this.wpImportInitParams = this._.cloneDeep(this.searchParams)
          } else {
            this.$refs.wpImportSettle.search(this.searchParams)
          }
        } else if (this.searchParams.workTab === '02') {
          if (this.$refs.wpImportIssue === undefined) {
            this.wpImportInitParams = this._.cloneDeep(this.searchParams)
          } else {
            this.$refs.wpImportIssue.search(this.searchParams)
          }
        } else if (this.searchParams.workTab === '03') {
          if (this.$refs.wpImportRecovery === undefined) {
            this.wpImportInitParams = this._.cloneDeep(this.searchParams)
          } else {
            this.$refs.wpImportRecovery.search(this.searchParams)
          }
        } else if (this.searchParams.workTab === '04') {
          if (this.$refs.wpImportTakeout === undefined) {
            this.wpImportInitParams = this._.cloneDeep(this.searchParams)
          } else {
            this.$refs.wpImportTakeout.search(this.searchParams)
          }
        } else if (this.searchParams.workTab === '05') {
          if (this.$refs.wpImportReturn === undefined) {
            this.wpImportInitParams = this._.cloneDeep(this.searchParams)
          } else {
            this.$refs.wpImportTakeout.search(this.searchParams)
          }
        }
      }
    },
    fnTabChange (tabId) {
      if (this.workTab === tabId) {
        return
      }
      this.searchParams.workTab = tabId
      this.searchAct()
    },
    changeDateRange (s, e) {
      this.searchParams.fromDt = s
      this.searchParams.toDt = e
    },
    changeCalcEiCatCd () {
      let workingEiCatCd = ''

      if (this.searchParams.workType === 'E') {
        workingEiCatCd = 'O'
      } else {
        workingEiCatCd = 'I'
      }

      this.$store.commit('auth/updateCalcEiCatCd', workingEiCatCd)
    }
  }
}

</script>
